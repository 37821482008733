import { $, $$ } from '../helpers/query-selector';
import { viewport, reducedMotion } from '../helpers/variables';

const videoHero = $('.js-video-hero');
const video = $('.js-video-hero video');
const controls = $('.js-video-hero-controls');
const playIcon = $('.js-video-hero-controls .js-play-icon');
const pauseIcon = $('.js-video-hero-controls .js-pause-icon');

let manuallyPaused = true;

function pauseVideo() {
  video.pause();
  playIcon.classList.remove('c-play-toggle__play--hide');
  pauseIcon.classList.add('c-play-toggle__pause--hide');
}

function playVideo() {
  video.play();
  playIcon.classList.add('c-play-toggle__play--hide');
  pauseIcon.classList.remove('c-play-toggle__pause--hide');
}

// Intersection Observer
const playObserver = new IntersectionObserver(
  (entries, observer) => {
    for (const entry of entries) {
      if (manuallyPaused) continue;
      if (entry.isIntersecting && video.paused) {
        playVideo();
      } else {
        pauseVideo();
      }
    }
  },
  { rootMargin: '0% 0% 0% 0%' }
);

function initVideoHero() {
  if (!videoHero) return;

  if (reducedMotion.matches) {
    manuallyPaused = true;
    pauseVideo();
  }

  // Pause button
  controls.addEventListener('click', () => {
    if (!manuallyPaused) {
      manuallyPaused = true;
      pauseVideo();
    } else {
      manuallyPaused = false;
      playVideo();
    }
  });

  playObserver.observe(videoHero);
}

export default initVideoHero;
