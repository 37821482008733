import { $, $$ } from '../helpers/query-selector';
import { gsap, ScrollToPlugin, ScrollTrigger } from 'gsap/all';
import { viewport, reducedMotion } from '../helpers/variables';

gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);

const history = $('.js-history');
const container = $('.js-history-container');
const scroll = $('.js-history-scroll');
const sections = $$('.js-history-section');
const nav = $('.js-nav-century');
const navWrapper = $('.js-nav-century-wrapper');
const navLinks = $$('.js-nav-century a');
const darkSections = $$('.js-history .bg-gray-dark');

let scrollTlDesktop;
let scrollTlMobile;

const breakpoint = window.matchMedia(
  `(min-width: ${1200 / 16}em) and (min-height: ${750 / 16}em)`
);

function initHistoryDesktop() {
  scrollTlDesktop = gsap.timeline({
    scrollTrigger: {
      id: 'triggerDesktop',
      pin: container,
      pinSpacing: false,
      scrub: true,
      trigger: scroll,
      start: 'top top',
      end: 'bottom bottom',
      onUpdate: self => {
        container.scrollLeft =
          self.progress * ((sections.length - 1) * container.offsetWidth);
      }
    },
    invalidateOnRefresh: true
  });

  // Update nav color for dark sections

  darkSections.forEach((darkSection, i) => {
    ScrollTrigger.create({
      id: 'triggerDarkSection' + i,
      trigger: darkSection,
      scroller: container,
      start: `left left+=${
        navWrapper.offsetLeft + navWrapper.offsetWidth / 2
      }px`,
      end: `right left+=${
        navWrapper.offsetLeft + navWrapper.offsetWidth / 2
      }px`,
      horizontal: true,
      toggleClass: {
        targets: nav,
        className: 'color-white'
      },
      invalidateOnRefresh: true
    });
  });

  // Animations
  const years = $$('.c-year', container);

  for (const year of years) {
    gsap.fromTo(
      year,
      {
        x: 300
      },
      {
        scrollTrigger: {
          trigger: year.parentNode.parentNode,
          horizontal: true,
          scroller: container,
          start: 'left right',
          end: 'left left',
          scrub: true
        },
        x: 0,
        ease: 'power1.out'
      }
    );
  }

  const images = $$('.l-history__column img', container);

  for (const image of images) {
    gsap.fromTo(
      image,
      {
        scale: 1.1
      },
      {
        scrollTrigger: {
          trigger: image.parentNode.parentNode,
          horizontal: true,
          scroller: container,
          start: 'left right',
          end: 'left left',
          scrub: true
        },
        scale: 1,
        ease: 'power1.out'
      }
    );
  }

  const fullScreenImages = $$('.l-history__section--fullScreen img', container);

  for (const fullScreenImage of fullScreenImages) {
    gsap.fromTo(
      fullScreenImage,
      {
        x: -150
      },
      {
        scrollTrigger: {
          trigger: fullScreenImage.parentNode.parentNode,
          horizontal: true,
          scroller: container,
          start: 'left right',
          end: 'left left',
          scrub: true
        },
        x: 0,
        ease: 'power1.out'
      }
    );
  }
  for (const fullScreenImage of fullScreenImages) {
    gsap.fromTo(
      fullScreenImage,
      {
        x: 0
      },
      {
        scrollTrigger: {
          trigger: fullScreenImage.parentNode.parentNode,
          horizontal: true,
          scroller: container,
          start: 'right right',
          end: 'right left',
          scrub: true
        },
        x: 150,
        ease: 'power1.out'
      }
    );
  }
}

function initHistoryMobile() {
  scrollTlMobile = gsap.timeline({
    scrollTrigger: {
      id: 'triggerMobile',
      pin: nav,
      pinSpacing: false,
      scrub: true,
      trigger: container,
      start: `${sections[0].offsetHeight}px top`,
      end: `bottom-=${sections[0].offsetHeight + navWrapper.offsetHeight}px top`
    },
    invalidateOnRefresh: true
  });
  nav.classList.remove('color-white');
}

function initNavLinks() {
  for (const link of navLinks) {
    link.addEventListener('click', e => {
      e.preventDefault();

      const url = new URL(e.target.href);
      const focusTarget = $(`[data-id="${url.hash.substring(1)}"]`);

      let scrollTarget = focusTarget;

      if (breakpoint.matches) {
        scrollTarget = $(`[data-scroll-id="${url.hash.substring(1)}"]`);
      }

      gsap.to(window, {
        duration: reducedMotion.matches ? 0 : 1,
        scrollTo: {
          y: scrollTarget,
          autoKill: true,
          offsetY: breakpoint.matches ? 0 : navWrapper.offsetHeight
        },
        ease: 'power2.inOut',
        onComplete: () => {
          // Move focus
          focusTarget.setAttribute('tabindex', '-1');
          focusTarget.classList.add('focus-hidden');
          focusTarget.focus({ preventScroll: true });
          focusTarget.removeAttribute('tabindex');
        }
      });
    });
  }
}

function init() {
  if (!history) return;

  if (breakpoint.matches) {
    initHistoryDesktop();
  } else {
    initHistoryMobile();
  }

  breakpoint.addListener(e => {
    if (e.matches) {
      scrollTlMobile.pause(0).kill(true);
      ScrollTrigger.getById('triggerMobile').kill(true);
      gsap.set(nav, { clearProps: true });
      initHistoryDesktop();
    } else {
      scrollTlDesktop.pause(0).kill(true);
      ScrollTrigger.getById('triggerDesktop').kill(true);

      darkSections.forEach((darkSection, i) => {
        ScrollTrigger.getById('triggerDarkSection' + i).kill(true);
      });

      gsap.set(container, { clearProps: true });
      gsap.set(nav, { clearProps: true });
      initHistoryMobile();
    }
  });

  initNavLinks();
}

export default init;
