import lazySizes from 'lazysizes';
import 'lazysizes/plugins/optimumx/ls.optimumx';
import { $$ } from '../helpers/query-selector';
import { saveData, reducedMotion } from '../helpers/variables';

// Image lazyload
lazySizes.cfg.init = false;

function initImageLazyLoad() {
  // Load lower resolution images if saveData
  if (saveData) {
    const images = $$('.lazyload');
    for (const image of images) image.dataset.optimumx = 1;
  }

  document.addEventListener('lazyloaded', e => {
    const parent = e.target.parentNode;
    parent.classList.add('c-image__wrapper--transparent');
  });

  lazySizes.init();
}

// Video lazyload
function initVideoLazyLoad() {
  const videos = $$('.js-video video');

  function fadeInVideo(video) {
    video.classList.remove('video-lazyload');
    video.classList.add('video-lazyloaded');
    video.parentNode.classList.add('c-video__wrapper--transparent');
  }

  function playVideo(video) {
    if (
      video.paused &&
      !video.hasAttribute('controls') &&
      !reducedMotion.matches &&
      !saveData
    ) {
      if (video.readyState === 0 || video.readyState === 1) {
        video.addEventListener('loadeddata', () => {
          video.play();
        });
      } else {
        video.play();
      }

      // Show controls if play not possible
      video.controlsTimeout = setTimeout(() => {
        if (video.paused) {
          video.setAttribute('controls', 'true');
        }
      }, 3000);
    }

    if (
      !video.hasAttribute('controls') &&
      (reducedMotion.matches || saveData)
    ) {
      video.setAttribute('controls', 'true');
    }
  }

  function pauseVideo(video) {
    if (!video.paused) video.pause();

    clearTimeout(video.controlsTimeout);
  }

  const loadObserver = new IntersectionObserver(
    (entries, observer) => {
      for (const entry of entries) {
        const video = entry.target;

        if (
          entry.isIntersecting &&
          video.classList.contains('video-lazyload')
        ) {
          const sources = $$('source', video);

          for (const source of sources) {
            source.setAttribute('src', source.dataset.src);
          }

          video.load();

          video.addEventListener('loadeddata', () => {
            fadeInVideo(video);
          });

          // Fallback
          setTimeout(() => {
            fadeInVideo(video);
          }, 3000);

          loadObserver.unobserve(video);
        }
      }
    },
    { rootMargin: '0% 0% 100% 0%' }
  );

  const playObserver = new IntersectionObserver(
    (entries, observer) => {
      for (const entry of entries) {
        const video = entry.target;

        if (entry.isIntersecting) {
          playVideo(video);
        } else {
          pauseVideo(video);
        }
      }
    },
    { rootMargin: '-30% 0%' }
  );

  for (const video of videos) {
    loadObserver.observe(video);
    playObserver.observe(video);
  }
}

function init() {
  initImageLazyLoad();
  initVideoLazyLoad();
}

export default init;
