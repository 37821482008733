import { $, $$ } from '../helpers/query-selector';
import onResize from '../helpers/on-resize';
import { reducedMotion, viewport } from '../helpers/variables';
import { gsap, ScrollToPlugin } from 'gsap/all';

gsap.registerPlugin(ScrollToPlugin);

const nav = $('.js-nav');
const dropdowns = $$('.js-dropdown');

const dropdownBg = 'l-nav--sub-nav-bg';
const dropdownActiveClass = 'l-nav__sublist--active';
const dropdownHoverClass = 'l-nav__item--dropdown-hover';

function showDropdownNav(dropdown, hover = false) {
  const toggle = $('.js-dropdown-toggle', dropdown);
  const subNavList = $('.js-dropdown-list', dropdown);

  toggle.setAttribute('aria-expanded', 'true');
  subNavList.classList.add(dropdownActiveClass);
  if (hover) dropdown.classList.add(dropdownHoverClass);

  nav.classList.add(dropdownBg);
  setDropdownBgHeight(dropdown);
}

function hideDropdownNav(dropdown, hover = false) {
  const toggle = $('.js-dropdown-toggle', dropdown);
  const subNavList = $('.js-dropdown-list', dropdown);

  toggle.setAttribute('aria-expanded', 'false');
  subNavList.classList.remove(dropdownActiveClass);

  if (nav.classList.contains(dropdownBg)) {
    nav.classList.remove(dropdownBg);
  }
}

function setDropdownBgHeight(dropdown) {
  const subNavList = $('.js-dropdown-list', dropdown);
  let bgHeight = 0;

  bgHeight = Math.max(subNavList.offsetHeight, bgHeight);

  nav.style.setProperty('--sub-nav-bg-height', `${bgHeight + 16}px`);
}

function initAnchors(dropdown) {
  const navAnchors = $$('.js-nav-anchor', dropdown);

  for (const anchor of navAnchors) {
    anchor.addEventListener('click', e => {
      e.preventDefault();

      hideDropdownNav(dropdown);

      const url = new URL(e.target.href);
      const target = $(url.hash);

      gsap.to(window, {
        duration: reducedMotion.matches ? 0 : 1,
        scrollTo: { y: target, autoKill: true },
        ease: 'power2.inOut',
        onComplete: () => {
          // Move focus
          target.setAttribute('tabindex', '-1');
          target.classList.add('focus-hidden');
          target.focus({ preventScroll: true });
          target.removeAttribute('tabindex');
        }
      });
    });
  }
}

function initNav() {
  for (const dropdown of dropdowns) {
    const toggle = $('.js-dropdown-toggle', dropdown);

    toggle.addEventListener('click', () => {
      if (dropdown.classList.contains(dropdownHoverClass)) return;

      if (toggle.getAttribute('aria-expanded') == 'true') {
        hideDropdownNav(dropdown);
      } else {
        for (const dropdown of dropdowns) {
          hideDropdownNav(dropdown);
        }
        showDropdownNav(dropdown);
      }
    });

    dropdown.addEventListener('mouseenter', () => {
      if (
        !dropdown.classList.contains(dropdownActiveClass) &&
        viewport.m.matches
      ) {
        showDropdownNav(dropdown);
      }
    });

    dropdown.addEventListener('mouseleave', () => {
      if (viewport.m.matches) {
        hideDropdownNav(dropdown);
      }
    });

    initAnchors(dropdown);
  }

  // Close dropdown on resize
  onResize(
    () => {
      for (const dropdown of dropdowns) {
        hideDropdownNav(dropdown);
      }
    },
    { horizontalOnly: true }
  );

  // Close dropdowns on main click
  $('.js-main').addEventListener('click', () => {
    for (const dropdown of dropdowns) {
      hideDropdownNav(dropdown);
    }
  });
}

export default initNav;
