import '../scss/app.scss'; // Vite requires css import in app.js

import initLazyLoad from './modules/lazyload';
import initNav from './modules/nav';
import initDropdownNav from './modules/dropdown-nav';
import initSetVh from './modules/set-vh';
import initVideoHero from './modules/video-hero';
import initHistory from './modules/history';
import { $ } from './helpers/query-selector';

async function init() {
  const polyfills = [];

  if (!('IntersectionObserver' in window)) {
    polyfills.push(import('intersection-observer'));
  }

  await Promise.all(polyfills);

  initLazyLoad();
  initNav();
  initDropdownNav();
  initSetVh();
  initVideoHero();
  initHistory();

  if ($('.js-map')) import('./modules/map').then(module => module.default());

  if ($('.js-dialog'))
    import('./modules/dialog').then(module => module.default());

  if ($('.js-timeline'))
    import('./modules/timeline').then(module => module.default());

  if ($('.js-slideshow'))
    import('./modules/slideshow').then(module => module.default());

  if ($('.js-history-teaser'))
    import('./modules/history-teaser').then(module => module.default());

  if ($('.js-timerange'))
    import('./modules/timerange').then(module => module.default());

  if ($('.js-scroll-to-top'))
    import('./modules/scroll-to-top').then(module => module.default());

  if ($('.js-slider'))
    import('./modules/slider').then(module => module.default());
}

init();
