import { $, $$ } from '../helpers/query-selector';
import { reducedMotion, viewport } from '../helpers/variables';

const header = $('.js-header');
const navToggle = $('.js-nav-toggle');
const navIcon = $('.js-nav-icon');
const navMobile = $('.js-nav-mobile');
const htmlEl = $('html');

const navIconActiveClass = 'c-nav-icon--active';
const navMobileActiveClass = 'l-nav__wrapper--active';

let navVisible = false;

// const focusTrap = createFocusTrap(header, { initialFocus: navToggle });

function showNav() {
  navVisible = true;

  navToggle.setAttribute('aria-expanded', 'true');
  navIcon.classList.add(navIconActiveClass);
  navMobile.classList.add(navMobileActiveClass);

  htmlEl.style.setProperty('overflow', 'hidden');

  // focusTrap.activate();
}

function hideNav() {
  navVisible = false;

  navToggle.setAttribute('aria-expanded', 'false');
  navIcon.classList.remove(navIconActiveClass);
  navMobile.classList.remove(navMobileActiveClass);

  htmlEl.style.removeProperty('overflow');

  // focusTrap.deactivate();
}

function init() {
  if (!navToggle) return;

  navToggle.addEventListener('click', () => {
    navVisible ? hideNav() : showNav();
  });

  // Close nav conditions
  viewport.m.addListener(e => {
    if (e.matches) hideNav();
  });

  document.addEventListener('keydown', e => {
    if (e.key === 'Esc' || e.key === 'Escape') hideNav();
  });
}

export { init as default, navVisible };
